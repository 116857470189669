<template>
  <div>
    <!-- <h1 class="sat_left">
      <p>
        {{ pageTitle }}
      </p>
    </h1>
    <hr /> -->
    <div class="phone" v-if="isPhone">
      <div class="phone-Plan free-plan">
        <div class="plan">
          <div v-if="isLogin && !isPro" class="currentPlan text-center">
            <h3>
              <i class="fas fa-check" />
              {{ $t("plans.currentPlan") }}
            </h3>
            <div class="timedLimited">
              <b>
                Forever
              </b>
            </div>
          </div>
          <div class="planContent">
            <div class="plan-items">
              <h5 class="mb-0">{{ $t("plans.free") }} - {{ $t("plans.lifetime") }}</h5>
              <div class="tCoin">
                <strong class="discountTCoin free">
                  $0 USD
                </strong>
              </div>
              <div class="apply mt-1">
                <router-link
                  v-if="!isPro"
                  :to="{ name: 'UserProfile', query: { active: 'balance' } }"
                >
                  <el-button type="success" style="width: 100%;" disabled>
                    Add Coins
                  </el-button>
                </router-link>
              </div>
            </div>
            <hr />
            <div class="featuresAvailable">
              <div>
                <span class="available">
                  <i class="fas fa-check" />
                  <b>1 Free Full-length DSAT Test </b>
                </span>
              </div>
              <div>
                <span class="available">
                  <i class="fas fa-check" />
                  <b>98 free questions</b>
                </span>
              </div>
              <div>
                <span class="available">
                  <i class="fas fa-check" />
                  <b>Pay-as-you-go</b>
                </span>
              </div>
              <div>
                <span class="available">
                  <i class="fas fa-check" />
                  <b>100% replica of the Bluebook platform</b>
                </span>
              </div>
              <div>
                <span class="available">
                  <i class="fas fa-check" />
                  <b>Immediate scoring</b>
                </span>
              </div>
              <div>
                <span class="available">
                  <i class="fas fa-check" />
                  <b>Explanations for all questions</b>
                </span>
              </div>
              <div>
                <span class="available">
                  <i class="fas fa-check" />
                  <b>Skill, timeline, and accuracy analysis</b>
                </span>
              </div>
              <div>
                <span class="available">
                  <i class="fas fa-check" />
                  <b>Valid forever</b>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="phone-Plan pro-plan">
        <div class="plan">
          <div v-if="isPro && !isLifetime" class="currentPlan text-center" >
            <h3 style="color: orange">
              <i class="fas fa-check" />
              {{ $t("plans.currentPlan") }}
            </h3>
            <div class="timedLimited">
              <template v-if="isLifetime">
                <b style="color: orange;" class="timedLimited">
                  Forever
                </b>
              </template>
              <template v-else>
                {{ `${$t("plans.planDuration")}${proTimeLimited} (GMT+8)` }}
              </template>
            </div>
          </div>
          <div class="planContent">
            <div class="plan-items">
              <h5 class="mb-0">{{ $t("plans.vip") }} - {{ $t("plans.thirtyDays") }}</h5>
              <div class="tCoin">
                <strong class="discountTCoin Pro">
                  $20 USD
                  <div class="originTCoin">
                    $40 USD
                    <div v-if="langIsEn" class="removeLine removeLineEn" />
                    <div v-else class="removeLine removeLineTw" />
                  </div>
                </strong>
              </div>
              <div class="discount">
                <div class="offers">
                  <span v-if="isTW"> Discount until {{ discountDate }} </span>
                  <span v-else> Discount until {{ discountDate }} </span>
                </div>
                <div class="offersTime">
                  {{ offersTime }}
                </div>
              </div>
              <div class="apply">
                <UpgradeButton
                  plansType="Month"
                  :isLogin="isLogin"
                  :isPro="isPro"
                  :isLifetime="isLifetime"
                  :type="month"
                  :proTimeLimited="proTimeLimited"
                  @upgradePlan="upgradePlans('Month')"
                />
              </div>
              <hr />
            </div>
            <div class="plan-items">
              <h5 class="mb-0">{{ $t("plans.vip") }} - {{ $t("plans.year") }}</h5>
              <div class="tCoin">
                <strong class="discountTCoin pro">
                  $99 USD
                  <div class="originTCoin">
                    $299 USD
                    <div v-if="langIsEn" class="removeLine removeLineEn" />
                    <div v-else class="removeLine removeLineTw" />
                  </div>
                </strong>
              </div>
              <div class="discount">
                <div class="offers">
                  <span v-if="isTW"> Discount until {{ discountDate }} </span>
                  <span v-else> Discount until {{ discountDate }} </span>
                </div>
                <div class="offersTime">
                  {{ offersTime }}
                </div>
              </div>
              <div class="apply">
                <UpgradeButton
                  plansType="Year"
                  :isLogin="isLogin"
                  :isPro="isPro"
                  :isLifetime="isLifetime"
                  :proTimeLimited="proTimeLimited"
                  @upgradePlan="upgradePlans('Year')"
                />
              </div>
              <hr />
            </div>
            <div class="plan-items">
              <h5 class="mb-0">{{ $t("plans.vip") }} - {{ $t("plans.lifetime") }}</h5>
              <div class="tCoin">
                <strong class="discountTCoin">
                  $249 USD
                  <div class="originTCoin">
                    $499 USD
                    <div v-if="langIsEn" class="removeLine removeLineEn" />
                    <div v-else class="removeLine removeLineTw" />
                  </div>
                </strong>
              </div>
              <div class="discount">
                <div class="offers">
                  <span v-if="isTW"> Discount until {{ discountDate }} </span>
                  <span v-else> Discount until {{ discountDate }} </span>
                </div>
                <div class="offersTime">
                  {{ offersTime }}
                </div>
              </div>
              <div class="apply">
                <UpgradeButton
                  plansType="LifeTime"
                  :isLogin="isLogin"
                  :isPro="isPro"
                  :isLifetime="isLifetime"
                  :proTimeLimited="proTimeLimited"
                  @upgradePlan="upgradePlans('LifeTime')"
                />
              </div>
            </div>
            <hr />
            <div>
              <div class="featuresAvailable">
                <div>
                  <span class="available">
                    <i class="fas fa-check" />
                    <b>
                      <span class="vipColor">13</span>
                      Full-length DSAT Tests (20 tests by 2024)
                    </b>
                  </span>
                </div>
                <div>
                  <span class="available">
                    <i class="fas fa-check" />
                    <b
                      ><span class="vipColor">2000+</span> questions (3000+ by
                      Jan 2024)</b
                    >
                  </span>
                </div>
                <div>
                  <span class="available">
                    <i class="fas fa-check" />
                    <b> <span class="vipColor"> Unlimited practices</span></b>
                  </span>
                </div>
                <div>
                  <span class="available">
                    <i class="fas fa-check" />
                    <b>100% replica of the Bluebook platform</b>
                  </span>
                </div>
                <div>
                  <span class="available">
                    <i class="fas fa-check" />
                    <b>Immediate scoring</b>
                  </span>
                </div>
                <div>
                  <span class="available">
                    <i class="fas fa-check" />
                    <b>Explanations for all questions</b>
                  </span>
                </div>
                <div>
                  <span class="available">
                    <i class="fas fa-check" />
                    <b>Skill, timeline, and accuracy analysis</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="plans" v-else>
      <div>
        <div v-if="isLogin" class="currentPlanTabs">
          <div
            :class="
              `${isPro ? 'hiddenFreePlan' : 'currentFreePlan'} ${
                isPro ? '' : 'displayPlan'
              }`
            "
          >
            <template v-if="!isPro">
              <h3>
                <i class="fas fa-check" />
                <strong>{{ $t("plans.currentPlan") }}</strong>
              </h3>
              <div>
                <b>
                  Forever
                </b>
              </div>
            </template>
          </div>
          <div :class="`currentProPlan ${isPro ? 'displayPlan' : ''}`">
            <template v-if="isPro">
              <h3 class="vipColor">
                <i class="fas fa-check" />
                {{ $t("plans.currentPlan") }}
              </h3>
              <span class="timedLimited">
                <template v-if="isLifetime">
                  <b style="color: orange;" class="timedLimited">
                    Forever
                  </b>
                </template>
                <template v-else>
                  {{ `${$t("plans.planDuration")}${proTimeLimited} (GMT+8)` }}
                </template>
              </span>
            </template>
          </div>
        </div>
        <div class="availablePlan">
          <div :class="isPro ? 'freePlan' : 'currentBorder freePlan'">
            <h3>{{ $t("plans.free") }}</h3>
            <div class="duration">
              {{ $t("plans.lifetime") }}
            </div>
            <div class="tCoin">
              <strong class="discountTCoin">
                $0
              </strong>
            </div>
            <div class="discount"></div>
            <div class="apply">
              <router-link
                v-if="!isPro"
                :to="{ name: 'UserProfile', query: { active: 'balance' } }"
              >
                <el-button type="success" style="width: 120px;" disabled>
                  Add Coins
                </el-button>
              </router-link>
            </div>
            <div class="featuresAvailable">
              <div>
                <span class="available">
                  <i class="fas fa-check" />
                  <b>1 Free Full-length DSAT Test </b>
                </span>
              </div>
              <div>
                <span class="available">
                  <i class="fas fa-check" />
                  <b>98 free questions</b>
                </span>
              </div>
              <div>
                <span class="available">
                  <i class="fas fa-check" />
                  <b>Pay-as-you-go</b>
                </span>
              </div>
              <div>
                <span class="available">
                  <i class="fas fa-check" />
                  <b>100% replica of the Bluebook platform</b>
                </span>
              </div>
              <div>
                <span class="available">
                  <i class="fas fa-check" />
                  <b>Immediate scoring</b>
                </span>
              </div>
              <div>
                <span class="available">
                  <i class="fas fa-check" />
                  <b>Explanations for all questions</b>
                </span>
              </div>
              <div>
                <span class="available">
                  <i class="fas fa-check" />
                  <b>Skill, timeline, and accuracy analysis</b>
                </span>
              </div>
              <div>
                <span class="available">
                  <i class="fas fa-check" />
                  <b>Valid forever</b>
                </span>
              </div>
            </div>
            <div class="bottomApply">
              <router-link
                v-if="!isPro"
                :to="{ name: 'UserProfile', query: { active: 'balance' } }"
              >
                <el-button type="success" style="width: 120px;" disabled>
                  Add Coins
                </el-button>
              </router-link>
            </div>
          </div>
          <div :class="isPro ? 'currentBorder vipPlan' : 'vipPlan'">
            <h3>{{ $t("plans.vip") }}</h3>
            <div class="vipPlans">
              <div class="lifetime">
                <div class="duration">
                  {{ $t("plans.thirtyDays") }}
                </div>
                <div class="tCoin">
                  <strong class="discountTCoin">
                    <span class="originTCoin">
                      $40 USD
                      <div v-if="langIsEn" class="removeLine removeLineEn" />
                      <div v-else class="removeLine removeLineTw" />
                    </span>
                    $20 USD
                  </strong>
                </div>
                <div class="discount">
                  <div class="offers">
                    <span v-if="isTW"> Discount until {{ discountDate }} </span>
                    <span v-else> Discount until {{ discountDate }} </span>
                  </div>
                  <div class="offersTime">
                    {{ offersTime }}
                  </div>
                </div>
                <div class="apply">
                  <UpgradeButton
                    plansType="Month"
                    :isLogin="isLogin"
                    :isPro="isPro"
                    :isLifetime="isLifetime"
                    :type="month"
                    :proTimeLimited="proTimeLimited"
                    @upgradePlan="upgradePlans('Month')"
                  />
                </div>
                <div class="featuresAvailable"></div>
              </div>
              <div class="lifetime">
                <div class="duration">
                  {{ $t("plans.year") }}
                </div>
                <div class="tCoin">
                  <strong class="discountTCoin">
                    <span class="originTCoin">
                      $299 USD
                      <div v-if="langIsEn" class="removeLine removeLineEn" />
                      <div v-else class="removeLine removeLineTw" />
                    </span>
                    $99 USD
                  </strong>
                </div>
                <div class="discount">
                  <div class="offers">
                    <span v-if="isTW"> Discount until {{ discountDate }} </span>
                    <span v-else> Discount until {{ discountDate }} </span>
                  </div>
                  <div class="offersTime">
                    {{ offersTime }}
                  </div>
                </div>
                <div class="apply">
                  <UpgradeButton
                    plansType="Year"
                    :isLogin="isLogin"
                    :isPro="isPro"
                    :isLifetime="isLifetime"
                    :proTimeLimited="proTimeLimited"
                    @upgradePlan="upgradePlans('Year')"
                  />
                </div>
                <div class="featuresAvailable"></div>
              </div>
              <div class="lifetime">
                <div class="duration">
                  {{ $t("plans.lifetime") }}
                </div>
                <div class="tCoin">
                  <strong class="discountTCoin">
                    <span class="originTCoin">
                      $499 USD
                      <div v-if="langIsEn" class="removeLine removeLineEn" />
                      <div v-else class="removeLine removeLineTw" />
                    </span>
                    $249 USD
                  </strong>
                </div>
                <div class="discount">
                  <div class="offers">
                    <span v-if="isTW"> Discount until {{ discountDate }} </span>
                    <span v-else> Discount until {{ discountDate }} </span>
                  </div>
                  <div class="offersTime">
                    {{ offersTime }}
                  </div>
                </div>
                <div class="apply">
                  <UpgradeButton
                    plansType="LifeTime"
                    :isLogin="isLogin"
                    :isPro="isPro"
                    :isLifetime="isLifetime"
                    :proTimeLimited="proTimeLimited"
                    @upgradePlan="upgradePlans('LifeTime')"
                  />
                </div>
                <div class="featuresAvailable"></div>
                <!-- <div class="featuresAvailable">
                  <div>
                    <span class="available">
                      <i class="fas fa-check" />
                      <b>
                        <span class="vipColor">13</span>
                        Full-length DSAT Tests (20 tests by 2024)
                      </b>
                    </span>
                  </div>
                  <div>
                    <span class="available">
                      <i class="fas fa-check" />
                      <b
                        ><span class="vipColor">2000+</span> questions (3000+ by
                        Jan 2024)</b
                      >
                    </span>
                  </div>
                  <div>
                    <span class="available">
                      <i class="fas fa-check" />
                      <b><span class="vipColor">Unlimited practices</span></b>
                    </span>
                  </div>
                  <div>
                    <span class="available">
                      <i class="fas fa-check" />
                      <b>100% replica of the Bluebook platform</b>
                    </span>
                  </div>
                  <div>
                    <span class="available">
                      <i class="fas fa-check" />
                      <b>Immediate scoring</b>
                    </span>
                  </div>
                  <div>
                    <span class="available">
                      <i class="fas fa-check" />
                      <b>Explanations for all questions</b>
                    </span>
                  </div>
                  <div>
                    <span class="available">
                      <i class="fas fa-check" />
                      <b>Skill, timeline, and accuracy analysis</b>
                    </span>
                  </div>
                  <div>
                    <span class="available">
                      <i class="fas fa-check" />
                      <b><span class="vipColor">Valid forever</span></b>
                    </span>
                  </div>
                </div> -->
                <!-- <div class="bottomApply">
                  <UpgradeButton
                    plansType="LifeTime"
                    :isLogin="isLogin"
                    :isPro="isPro"
                    :isLifetime="isLifetime"
                    :proTimeLimited="proTimeLimited"
                    @upgradePlan="upgradePlans('LifeTime')"
                  />
                </div> -->
              </div>
            </div>
            <div>
              <div class="featuresAvailable">
                <div>
                  <span class="available">
                    <i class="fas fa-check" />
                    <b>
                      <span class="vipColor">13</span>
                      Full-length DSAT Tests (20 tests by 2024)
                    </b>
                  </span>
                </div>
                <div>
                  <span class="available">
                    <i class="fas fa-check" />
                    <b
                      ><span class="vipColor">2000+</span> questions (3000+ by
                      Jan 2024)</b
                    >
                  </span>
                </div>
                <div>
                  <span class="available">
                    <i class="fas fa-check" />
                    <b> <span class="vipColor"> Unlimited practices</span></b>
                  </span>
                </div>
                <div>
                  <span class="available">
                    <i class="fas fa-check" />
                    <b>100% replica of the Bluebook platform</b>
                  </span>
                </div>
                <div>
                  <span class="available">
                    <i class="fas fa-check" />
                    <b>Immediate scoring</b>
                  </span>
                </div>
                <div>
                  <span class="available">
                    <i class="fas fa-check" />
                    <b>Explanations for all questions</b>
                  </span>
                </div>
                <div>
                  <span class="available">
                    <i class="fas fa-check" />
                    <b>Skill, timeline, and accuracy analysis</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { Decimal } from "decimal.js";
import UpgradeButton from "@/views/plans/UpgradeButton.vue";
import plansApi from "@/apis/plans";
import moment from "moment";

export default {

  components: {
    UpgradeButton
  },

  mixins: [],

  props: [],
  data() {
    return {
      moment,
      isLogin: true,
      // proTimeLimited: "",
      langIsEn: "",
      isTW: false
    };
  },
  computed: {
    offersTime() {
      return "";
    },
    pageTitle() {
      return this.$t("pageTitle.PlansAndPricing");
    },
    isPro() {
      return this.$store.getters["user/getIsPro"];
    },
    proTimeLimited() {
      return this.$store.getters["user/getProTimeLimited"];
    },
    isLifetime() {
      let isLifetime = false;
      if (this.proTimeLimited) {
        isLifetime =
          moment("2099-01-01").format("YYYY-MM-DD") ===
          moment(this.proTimeLimited).format("YYYY-MM-DD");
      }
      return isLifetime;
    },
    discountDate() {
      let date = moment().format("YYYY-MM-DD");
      let today = moment().format("D");
      if (today > 15) {
        date = moment()
          .endOf("month")
          .format("YYYY-MM-DD");
      } else {
        date = moment()
          .date(15)
          .format("YYYY-MM-DD");
      }
      return date;
    }
  },
  watch: {},

  mounted() {},

  methods: {
    async upgradePlans(category) {
      let extendInformation = {};
      switch (category) {
        case "LifeTime":
          extendInformation = {
            plan_id: 15
          };
          break;
        case "Year":
          extendInformation = {
            plan_id: 19
          };
          break;
        case "Month":
          extendInformation = {
            plan_id: 18
          };
          break;
        default:
      }
      try {
        if (this.isPro && category === "Year") {
          let extendProTimeLimited = moment(this.proTimeLimited);
          let information = {
            month: 12,
            suffix: "s",
            date: extendProTimeLimited.add(1, "years").format("YYYY-MM-DD")
          };
          await this.$confirm(
            this.$t("plans.extendNotice", information),
            this.$t("message.notice"),
            {
              confirmButtonText: this.$t("message.continue"),
              cancelButtonText: this.$t("message.cancel"),
              type: "warning"
            }
          );
        }
        if (this.isPro && category === "Month") {
          let extendProTimeLimited = moment(this.proTimeLimited);
          let information = {
            month: 1,
            suffix: "",
            date: extendProTimeLimited.add(1, "months").format("YYYY-MM-DD")
          };
          await this.$confirm(
            this.$t("plans.extendNotice", information),
            this.$t("message.notice"),
            {
              confirmButtonText: this.$t("message.continue"),
              cancelButtonText: this.$t("message.cancel"),
              type: "warning"
            }
          );
        }
      } catch (e) {
        return false;
      }
      try {
        const res = await plansApi.upgradePlan(extendInformation);
        if (res.url) {
          window.location.href = res.url;
        }
        this.$store.dispatch("user/getProfile");
      } catch (e) {
        //
      }
    }
  }
};
</script>
<style scoped lang="scss">
.sat_left {
  margin: 20px 0;
  display: flex;
  color: var(--themeColor);
  text-decoration: none;
  align-items: center;
}
.sat_left > p {
  font-size: 40px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 0px;
}
.container {
  // max-width: 100vw;
  // margin: 0px;
  padding-bottom: 40px;
  // background: #f4f5f8;

  .pageTitle {
    padding-left: 32px !important;
  }

  .plans {
    margin: 0px auto;
    // display: grid;
    // grid-gap: 12px;
    // grid-template-columns: calc((100vw - 60px) / 4) auto;

    .pickPlanDesc {
      padding-top: 20px;
      .slogan {
        font-size: 42px;
        color: var(--themeColor);
      }
      .subSlogan {
        margin-top: -12px;
        font-size: 30px;
      }
      .desc {
        margin: 24px 0px 49px 0px;
        height: 76px;
      }

      .features {
        border-top: 1px solid #ddd;
        border-left: 1px solid #ddd;
        padding: 8px 4px 0px 8px;
        margin: 303px -10px 0px 20px;
        display: grid;
        grid-gap: 4px;
        grid-template-rows: repeat(13, 50px);

        .deviceIcon {
          margin-right: 6px;
        }

        .practiceItem {
          margin-left: 16px;
        }
      }

      .isNotExistCurrentPlan {
        margin: 56px -10px 0px 20px;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .container {
    .plans {
      .pickPlanDesc {
        .slogan {
          font-size: 36px;
        }
        .subSlogan {
          font-size: 24px;
        }
        .desc {
          height: 94px;
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .container {
    .plans {
      .pickPlanDesc {
        .features {
          margin: 119px -10px 0px 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 1220px) {
  .container {
    // padding: 20px 0px;
    .plans {
      width: calc(100vw - 60px);
      margin: 0px auto;
      display: flex;
      flex-direction: column;

      .pickPlanDesc {
        .slogan {
          font-size: 42px;
          color: var(--themeColor);
        }
        .subSlogan {
          margin-top: -12px;
          font-size: 30px;
        }
        .desc {
          margin: 24px 0px 0px 0px;
          height: 60px;
        }

        .features {
          display: none;
        }
      }
    }
  }
}
</style>

<style scoped lang="scss">
.upgradeButton {
  width: 200px;
  background: orange;
  color: #ffffff;
}

.currentPlanTabs {
  display: flex;

  .displayPlan {
    padding: 12px 0px;
    background: #fff;
    border-bottom: 1px solid #ddd;
    box-shadow: 1px 1px 16px 4px #cccccc;
    position: relative;
  }

  .displayPlan::after {
    content: "";
    position: absolute;
    height: 17px;
    bottom: -18px;
    left: 0px;
    width: calc(100%);
    background: #fff;
  }

  strong {
    margin-left: 4px;
  }

  .timedLimited {
    color: #00000077;
  }

  .currentFreePlan {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 73px;
    width: calc(100% / 4 - 2px);
    text-align: center;
  }
  .currentFreePlan.displayPlan {
    width: calc(100% / 4);
    border: 2px solid #aaa;
    border-bottom: none;
  }

  .hiddenFreePlan {
    width: calc(100% / 4);
    text-align: center;
  }

  .currentProPlan {
    min-height: 73px;
    width: calc(100% / 4 * 3);
    text-align: center;
  }
  .currentProPlan.displayPlan {
    border: 2px solid orange;
    border-bottom: none;
  }
}
.featuresAvailable {
  padding: 12px;
  width: 100%;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 3rem;
    .available {
      width: 100%;
      display: flex;
      padding: 0 40px;
      // justify-content: space-between;
      color: var(--themeColor);
      line-height: 1rem;
      align-items: center;
      b {
        flex: 1;
        color: #212529;
        text-align: left;
        margin-left: 1rem;
      }
    }
  }
}
.availablePlan {
  display: flex;
  box-shadow: 1px 1px 16px 4px #cccccc;
  background: #ffffff;
  .freePlan {
    border-right: 1px solid #ddd;
    width: calc(100% / 4);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 0px;

    h3 {
      color: var(--themeColor);
      font-size: 2.5rem;
    }
    .duration {
      margin-top: 20px;
      font-weight: 500;
      font-size: 2rem;
      color: #aaa;
    }
    .tCoin {
      // height: 86px;
      display: flex;
      align-items: center;
      white-space: nowrap;

      .discountTCoin {
        font-size: clamp(1rem, 2.5vw, 8rem);
        color: var(--themeColor);
      }
    }
    .discount {
      height: 44px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .apply {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 90px;
    }
    .bottomApply {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 80px;
    }
    .featuresAvailable {
      border-top: 1px solid #ddd;
      // display: grid;
      // grid-gap: 4px;
      // grid-template-rows: repeat(13, 50px);
      padding: 12px;
      width: 100%;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 3rem;
        .available {
          width: 100%;
          display: flex;
          padding: 0 40px;
          // justify-content: space-between;
          color: var(--themeColor);
          line-height: 1rem;
          align-items: center;
          b {
            flex: 1;
            color: #212529;
            text-align: left;
            margin-left: 1rem;
          }
        }
      }
    }
  }
  .freePlan.currentBorder {
    border: 2px solid #aaa;
    border-top: none;
  }
  .vipPlan {
    padding-top: 12px;
    width: calc(100% / 4 * 3);
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
      color: orange;
      font-size: 2.5rem;
    }
    .vipPlans {
      width: 100%;
      display: flex;
      align-items: center;

      .lifetime:last-child {
        // border-left: 1px solid #ddd;
        // border-top: 1px solid #ddd;
      }

      div {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .duration {
          margin-top: 20px;
          font-weight: 500;
          font-size: 2rem;
          color: #aaa;
        }
        .tCoin {
          // height: 86px;
          white-space: nowrap;
          .originalTCoin {
            font-size: 24px;
            color: #ccc;
            position: relative;
            margin-bottom: -16px;
          }

          .originalTCoin::after {
            content: "";
            width: 92px;
            border-top: 2px solid #ccc;
            position: absolute;
            left: 0px;
            top: 16px;
            transform: rotate(-6deg);
          }

          .discountTCoin {
            font-size: clamp(1rem, 2.5vw, 8rem);
            color: orange;
          }
        }
        .originTCoin {
          font-size: 20px;
          color: #ccc;
          position: relative;
          line-height: 0.8;
          font-weight: bold;
          display: inline-block;

          .removeLine {
            border-top: 2px solid #ccc;
            position: absolute;
            top: 10px;
            transform: rotate(-6deg);
          }

          .removeLineEn {
            width: 120px;
          }

          .removeLineTw {
            width: 80px;
            transform: rotate(-10deg);
          }
        }
        .discount {
          height: 44px;
          width: 100%;
          text-align: center;
          font-size: 24px;
          font-weight: bold;
          white-space: nowrap;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .discountPrice {
            flex-direction: row;
          }

          .offers {
            font-size: 16px;
            color: red;
          }
        }
        .apply {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 90px;
        }
        .bottomApply {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 80px;
        }
        .featuresAvailable {
          border-top: 1px solid #ddd;
          // display: grid;
          // grid-gap: 4px;
          // grid-template-rows: repeat(13, 50px);
          padding: 12px 0;
          width: 100%;
          div {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            height: 3rem;
            .available {
              width: 100%;
              display: flex;
              padding: 0 40px;
              // justify-content: space-between;
              color: orange;
              line-height: 1rem;
              align-items: center;
              b {
                flex: 1;
                text-align: left;
                margin-left: 1rem;
                color: #212529;
              }
            }
          }
        }
      }
    }
  }
  .vipPlan.currentBorder {
    border: 2px solid orange;
    border-top: none;
  }
}
.vipColor {
  font-weight: 900;
  color: orange;
}

@media screen and (max-width: 1400px) {
  .availablePlan {
    .vipPlan {
      .vipPlans {
        div {
          .discount {
            font-size: 24px;
            .originPrice {
              .removeLine {
                top: 10px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .availablePlan {
    .freePlan {
      .discount {
        height: 39px;
      }
    }

    .vipPlan {
      .vipPlans {
        div {
          .discount {
            margin-top: 0px;
            font-size: 18px;
            .originPrice {
              .removeLine {
                width: 120px;
                top: 8px;
              }
            }
          }
        }
      }
    }
  }
}
</style>

<style scoped lang="scss">
.phone-Plan {
  .timedLimited {
    color: #00000077;
  }
  margin-bottom: 4rem;
  .plan {
    background: #fff;
    box-shadow: 1px 1px 16px 4px #cccccc;
    margin-bottom: 24px;
  }
  .currentPlan {
    border-bottom: 1px solid #ddd;
    padding: 10px 20px;
  }
  .planContent {
    padding: 24px 0;
    .plan-items {
      padding: 0 20px;
    }
    .tCoin {
      .discountTCoin {
        color: orange;
        font-size: 42px;
        .originTCoin {
          font-size: 20px;
          color: #aaaaaa;
          position: relative;
          line-height: 0.8;
          font-weight: bold;
          display: inline-block;
          .removeLine {
            border-top: 2px solid #aaaaaa;
            position: absolute;
            top: 8px;
            transform: rotate(-6deg);
          }

          .removeLineEn {
            width: 80px;
          }

          .removeLineTw {
            width: 80px;
            transform: rotate(-10deg);
          }
        }
      }
    }
    .apply {
      text-align: center;
      ::v-deep .upgradeButton {
        width: 100% !important;
      }
    }

    .discount {
      height: 44px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .offers {
      font-size: 16px;
      color: red;
    }
}
}
</style>
